.tourRootClassName.mediakit-tour .mediakit-tour-arrow:before {
    background-color: #001529;
}

.tourRootClassName .mediakit-tour-content .mediakit-tour-inner {
    background-color: #001529;
    color: rgba(255, 255, 255, 0.88);
}

.tourRootClassName .mediakit-tour-content .mediakit-tour-inner > button.mediakit-tour-close {
    color: rgba(255, 255, 255, 0.88);
}

.tourRootClassName .mediakit-tour-content .mediakit-tour-inner .mediakit-tour-footer .mediakit-tour-indicators .mediakit-tour-indicator-active.mediakit-tour-indicator {
    background: #ffffff;
}

.tourRootClassName .mediakit-tour-content .mediakit-tour-inner .mediakit-tour-footer .mediakit-tour-indicators .mediakit-tour-indicator {
    background: rgba(255, 255, 255, 0.15);
}
