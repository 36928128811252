.insights-button.mediakit-btn.mediakit-btn-default.mediakit-btn-color-default.mediakit-btn-variant-outlined.mediakit-btn-lg {
    color: #FFFFFFE0;
    background: rgba(255, 255, 255, 0.24);
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 1px 2px 0 rgba(255, 255, 255, 0.25) inset, 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        border-color: rgba(255, 255, 255, 0.25);
        background-color: rgba(255, 255, 255, 0.35);
        box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.35) inset, 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    }
}